import request from '@/utils/request'
export function getOutPatientFeeList(data) {
  return request({
    url: 'yyHis/getOutPatientFeeList',
    method: 'POST',
    data: data
  })
}
export function getOutPatientFeeDetail(data) {
  return request({
    url: 'yyHis/getOutPatientFeeDetail',
    method: 'POST',
    data: data
  })
}
export function outPatientPreSet(data) {
  return request({
    url: 'yyHis/outPatientPreSet',
    method: 'POST',
    data: data
  })
}
export function outPatientForSet(data) {
  return request({
    url: 'yyHis/outPatientForSet',
    method: 'POST',
    data: data
  })
}
